var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.submit)
                      },
                    },
                  },
                  [
                    _c(
                      "section",
                      { staticClass: "section has-text-centered" },
                      [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("verify_your_phone_number"))),
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: { name: "code", rules: "required|max:4" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "b-field",
                                      {
                                        attrs: {
                                          type: {
                                            "is-danger": !!errors.length,
                                          },
                                          message: errors,
                                        },
                                      },
                                      [
                                        _c("b-input", {
                                          ref: "code",
                                          attrs: { type: "text", name: "code" },
                                          model: {
                                            value: _vm.code,
                                            callback: function ($$v) {
                                              _vm.code = $$v
                                            },
                                            expression: "code",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "section",
                      { staticClass: "section mt-6" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              rounded: "",
                              tag: "button",
                              type: "is-link is-medium is-fullwidth has-text-weight-bold",
                              "native-type": "submit",
                              loading: _vm.isSubmitted,
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("log_in")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }