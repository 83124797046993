<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent="handleSubmit(submit)">
        <section class="section has-text-centered">
          <p>{{ $t('verify_your_phone_number') }}</p>
          <ValidationProvider name="code" rules="required|max:4" v-slot="{ errors }">
            <b-field :type="{ 'is-danger': !!errors.length }" :message="errors">
              <b-input type="text" v-model="code" name="code" ref="code" />
            </b-field>
          </ValidationProvider>
        </section>
        <section class="section mt-6">
          <b-button
            rounded
            tag="button"
            type="is-link is-medium is-fullwidth has-text-weight-bold"
            native-type="submit"
            :loading="isSubmitted"
          >
            {{ $t('log_in') }}
          </b-button>
        </section>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  computed: {
    ...mapFields(['code']),
  },
  data: () => ({ isSubmitted: false }),
  mounted() {
    this.$refs.code.focus();
  },
  methods: {
    submit: function () {
      if (this.$store.state.userType === 'company') {
        this.$store.dispatch('company/login', { code: this.code });
      } else {
        this.$store.dispatch('individual/login', { code: this.code });
      }
      this.isSubmitted = true;
    },
  },
  head() {
    return {
      title: this.$t('title'),
    };
  },
};
</script>
